<template>
  <CBox>
    <CBox bg="bgSocialBadge" h="37px">
      <CGrid v-bind:templateColumns="templateColumns" :gap="2" h="inherit">
        <CBox align="center" py="auto" my="auto">
          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="11px"
            fontWeight="bold"
            color="headerTable"
            >Rank</CText
          >
        </CBox>
        <CBox align="center" py="auto" my="auto">
          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="11px"
            fontWeight="bold"
            color="headerTable"
            >ID</CText
          >
        </CBox>
        <CBox py="auto" my="auto" v-for="(r, i) of metricsName" :key="i">
          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="11px"
            fontWeight="bold"
            color="headerTable"
            >{{ r }}</CText
          >
        </CBox>
        <CBox align="center" py="auto" my="auto">
          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="11px"
            fontWeight="bold"
            color="headerTable"
            >Link</CText
          >
        </CBox>
      </CGrid>
    </CBox>
    <CBox>
      <CBox v-for="(r, i) of metricsSummaryByTag" :key="i">
        <CGrid v-bind:templateColumns="templateColumns" :gap="4" h="inherit">
          <CBox align="center" py="auto" my="auto" mb="0px">
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="12px"
              fontWeight="bold"
              >{{ i + 1 }}</CText
            >
          </CBox>
          <CBox align="center" px="auto" my="auto" mb="0px">
            <CText
              fontStyle="bold"
              fontSize="14px"
              class="pointer"
              color="activeMenu"
              @click.native="handleDetail(r.tag)"
              >{{ r.tag }}</CText
            >
          </CBox>
          <CBox
            py="auto"
            my="auto"
            mb="0px"
            v-for="(r2, i2) of metricsName"
            :key="i2"
          >
            <CText fontStyle="normal" fontSize="14px">
              {{ formatMetrics(metricsSummaryByTag[i][r2], r2) }}
            </CText>
          </CBox>
          <CBox align="center" py="0px" mx="0px" mt="10px">
            <CText
              fontStyle="bold"
              fontSize="14px"
              class="pointer"
              color="activeMenu"
              @click.native="handleDetail(r.tag)"
              >Detail</CText
            >
          </CBox>
        </CGrid>
        <CDivider />
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
export default {
  props: {
    metricsName: [],
    metricsSummaryByTag: [],
  },
  // watch: {
  //   'metricsSummaryByTag': function() {
  //     console.log(this.metricsSummaryByTag)

  //   },
  //   'metricsName': function() {
  //     console.log(this.metricsName)
  //   }
  // },
  methods: {
    formatMetrics(value, label) {
      switch (label) {
        case "CPU":
          return Math.round(value * 100) / 100 + "%";
        case "Memory":
          return parseFloat(value / 1000000).toFixed(2) + "MB";
        case "Time Duration":
          return parseFloat(value / 1000).toFixed(2) + "s";
        case "Request per Second":
          return Math.round(value) + "";
        default:
          return value + "";
      }
    },
    handleDetail(val) {
      this.$emit("detailTags", val);
    },
  },
  computed: {
    templateColumns() {
      const itemLength = this.metricsName.length;
      const output = "1fr 2fr " + " 2fr ".repeat(itemLength) + " 1fr ";
      return output;
    },
  },
};
</script>
