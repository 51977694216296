import { render, staticRenderFns } from "./Metrics.vue?vue&type=template&id=ee1ed996"
import script from "./Metrics.vue?vue&type=script&lang=js"
export * from "./Metrics.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CDivider: require('@chakra-ui/vue').CDivider, CLink: require('@chakra-ui/vue').CLink, CImage: require('@chakra-ui/vue').CImage, CStack: require('@chakra-ui/vue').CStack, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CIcon: require('@chakra-ui/vue').CIcon, CCollapse: require('@chakra-ui/vue').CCollapse, CGrid: require('@chakra-ui/vue').CGrid, CSpinner: require('@chakra-ui/vue').CSpinner})
