<template>
  <CBox>
    <CBox
      bg="white"
      minH="100px"
      borderRadius="5px"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    >
      <CBox py="20px" mx="20px" justifyContent="space-between" display="flex">
        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="15px"
          fontWeight="600"
          color="fontMain"
        >
          Hashtags
        </CText>
        <!-- <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="15px"
          fontWeight="600"
          color="activeMenu"
          class="pointer"
          @click.native="$router.push({ name: 'Hashtags', params: { id } })"
        >
          Lihat Semua
        </CText> -->
      </CBox>
      <CDivider mt="0" />
      <CBox display="flex" justifyContent="center" v-if="loading">
        <CSpinner />
      </CBox>
      <CBox v-for="(r, i) in currentHashtags" :key="i">
        <CText py="4px" mx="20px" mb="2" class="pointer" :key="i">
          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="14px"
            fontWeight="500"
            class="pointer"
            color="activeMenu"
            @click.native="handleChange(r.tag.name)"
          >
            {{ r.tag.name }}
          </CText>
        </CText>
        <CDivider />
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { GET_TEST_HASHTAG_FILTERS } from "@/store/test.module";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    currentTest() {
      return this.$store.getters.currentTest;
    },
    currentHashtags() {
      return this.$store.getters.currentHashtags;
    },
    currentLeaderboardsHashtagActiveFilters() {
      return this.$store.getters.currentTestHashtagActiveFilters;
    },
  },
  mounted() {
    // this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        await this.$store.dispatch(GET_TEST_HASHTAG_FILTERS, {
          app_type_id: this.id,
        });
      } catch (e) {
        // console.log(e);
      } finally {
        this.loading = false;
      }
    },
    handleChange(val) {
      this.$emit("changeTags", val);
    },
  },
};
</script>
