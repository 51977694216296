<template>
  <CBox>
    <CBox>
      <Loading :active="isLoading" />
      <CGrid
        :templateColumns="
          currentAppType.view_type == 'RECRUITMENT'
            ? '12fr'
            : ['12fr', '12fr', '2fr 10fr', '2fr 10fr']
        "
        :gap="[3, 3, 6, 6]"
      >
        <CBox v-if="currentAppType.view_type != 'RECRUITMENT'" pb="2em">
          <CBox
            bg="white"
            minH="100px"
            borderRadius="5px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox py="20px" mx="20px">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="16px"
                fontWeight="600"
                color="fontMain"
                >Metrics Label</CText
              >
            </CBox>
            <CBox v-for="(r, i) of currentTestMetrics" :key="i">
              <CText
                py="4px"
                mx="20px"
                mb="2"
                fontFamily="Montserrat"
                fontStyle="normal"
                class="pointer"
                fontSize="14px"
                fontWeight="600"
                color="activeMenu"
                @click.native="changeLabelMetrics(i)"
              >
                {{ r.label }}
              </CText>
              <!-- <CText
              py="4px" ml="36px" mb="2"
              class="pointer"
              v-for="(tag, idx) in r.tags"
              :key="idx"
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="14px"
              fontWeight="600"
              color="activeMenu"
              @click.native="$router.push({ name: 'Leaderboard', params: { id }, query: { tags: tag } })">
                #{{ tag }}
              </CText> -->
              <CDivider />
            </CBox>
          </CBox>
          <!-- <Leaderboard
            v-if="currentAppType.id"
            :id="parseInt(currentAppType.id)"
          />-->
          <HashtagFilterApp
            @changeTags="changeTags"
            v-if="currentAppType.id && tags != ''"
            :id="parseInt(currentAppType.id)"
          />
        </CBox>
        <CBox>
          <CBox
            bg="white"
            borderRadius="10px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox minH="100px">
              <CBox py="40px" px="20px">
                <CText
                  whiteSpace="wrap"
                  mb="10px"
                  fontFamily="Montserrat"
                  :fontSize="['20px', '24px', '34px', '36px']"
                  fontWeight="bolder"
                  color="fontMain"
                  >{{ currentAppType.name }}</CText
                >
                <!-- <CText
                  pb="20px"
                  fontFamily="Montserrat"
                  color="fontInformation"
                  fontSize="12px"
                  >{{ currentAppType.description }}</CText
                > -->
                <div
                  v-chakra
                  pb="20px"
                  fontFamily="Montserrat"
                  fontSize="14px"
                  color="fontMain"
                  v-html="currentAppType.description"
                  style="text-decoration: underline; color: #007bff"
                ></div>
                <CBox pb="20px" v-if="currentAppType.design_link">
                  <CText
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    >Design URL</CText
                  >
                  <CLink
                    color="linkColor"
                    fontFamily="Montserrat"
                    fontSize="14px"
                    :href="currentAppType.design_link"
                    target="_blank"
                    >Klik link untuk membuka</CLink
                  >
                </CBox>
                <CBox pb="20px" v-if="currentAppType.postman_link">
                  <CText
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    >API Documentation</CText
                  >
                  <CLink
                    color="linkColor"
                    fontFamily="Montserrat"
                    fontSize="14px"
                    :href="currentAppType.postman_link"
                    target="_blank"
                    >Klik link untuk membuka</CLink
                  >
                </CBox>
                <CBox pb="20px" v-if="currentAppType.requirement_doc_link">
                  <CText
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    >Requirement Docs</CText
                  >
                  <CLink
                    color="linkColor"
                    fontFamily="Montserrat"
                    fontSize="14px"
                    :href="currentAppType.requirement_doc_link"
                    target="_blank"
                    >Klik link untuk membuka</CLink
                  >
                </CBox>
                <CStack
                  d="flex"
                  direction="row"
                  v-if="
                    currentAppType.stack_tech &&
                    currentAppType.stack_tech.length > 0
                  "
                >
                  <CBox h="40px">
                    <CBox
                      w="inherit"
                      h="inherit"
                      d="table-cell"
                      verticalAlign="middle"
                    >
                      <CText
                        fontFamily="Montserrat"
                        color="fontMain"
                        fontSize="14px"
                        fontWeight="bolder"
                        >List Stack :</CText
                      >
                    </CBox>
                  </CBox>
                  <CBox
                    bg="bgSocialBadge"
                    borderRadius="full"
                    h="40px"
                    v-for="(v, i) in currentAppType.stack_tech"
                    :key="i"
                  >
                    <CBox
                      w="inherit"
                      h="inherit"
                      d="table-cell"
                      verticalAlign="middle"
                      v-if="v.icon"
                    >
                      <CImage
                        :src="require(`@/assets/icon/${v.icon}.png`)"
                        pl="5px"
                        py="5px"
                        w="28px"
                      />
                    </CBox>
                    <CBox
                      d="table-cell"
                      verticalAlign="middle"
                      h="inherit"
                      px="10px"
                    >
                      <CText
                        fontFamily="Montserrat"
                        color="fontMain"
                        fontSize="12px"
                        fontWeight="600"
                        >{{ v.name }}</CText
                      >
                    </CBox>
                  </CBox>
                  <!-- <CBox bg="bgStackBadge" borderRadius="full">
                    <CBox
                      w="inherit"
                      h="inherit"
                      d="table-cell"
                      verticalAlign="middle"
                    >
                      <CImage
                        :src="require('@/assets/icon/ic-nodejs.png')"
                        pl="5px"
                        py="5px"
                      />
                    </CBox>
                    <CBox
                      d="table-cell"
                      verticalAlign="middle"
                      h="inherit"
                      px="10px"
                    >
                      <CText
                        fontFamily="Montserrat"
                        color="white"
                        fontSize="12px"
                        >NodeJS</CText
                      >
                    </CBox>
                  </CBox> -->
                </CStack>
              </CBox>
            </CBox>
            <CDivider />
            <!-- <CBox my="30px" mx="20px">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="20px"
                fontWeight="bold"
                color="fontMain"
                >Detail Instruksi</CText
              >
            </CBox> -->
            <CBox
              mx="20px"
              mb="20px"
              minH="100px"
              v-for="(v, i) in currentTestScenarios.list"
              :key="i"
              borderRadius="5px"
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.1)"
            >
              <CBox mx="20px" py="20px">
                <CText
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="bold"
                  color="fontMain"
                  >{{ v.title }}</CText
                >
                <CBox v-if="v.description" px="10px">
                  <CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    color="fontMain"
                    >{{ v.description }}</CText
                  >
                  <CList
                    styleType="disc"
                    v-if="v.description_list && v.description_list.length > 0"
                    pl="10px"
                  >
                    <CListItem
                      v-for="(v12, i12) in v.description_list"
                      :key="i12"
                      fontFamily="Montserrat"
                      fontSize="14px"
                      color="fontMain"
                      >{{ v12 }}</CListItem
                    >
                  </CList>
                </CBox>
              </CBox>
              <CBox>
                <CDivider mb="0px" />
                <CBox
                  px="20px"
                  py="10px"
                  class="pointer"
                  d="flex"
                  justifyContent="space-between"
                  bgColor="bgTestList"
                  @click.native="
                    $store.state.testScenarios.testScenarios.list[i].isOpen =
                      !v.isOpen
                  "
                >
                  <CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="bold"
                    color="activeMenu"
                    >Detail Test Scenario</CText
                  >
                  <CBox>
                    <CIcon v-if="!v.isOpen" name="chevron-down" />
                    <CIcon v-if="v.isOpen" name="chevron-up" />
                  </CBox>
                </CBox>
                <CCollapse :isOpen="v.isOpen">
                  <CBox
                    px="20px"
                    pt="20px"
                    v-for="(v2, i2) in v.list"
                    :key="i2"
                    bgColor="bgTestList"
                  >
                    <CText
                      fontFamily="Montserrat"
                      fontSize="14px"
                      fontWeight="bold"
                      color="activeMenu"
                      >{{ v2.title }}</CText
                    >
                    <CText
                      v-if="v2.description"
                      fontFamily="Montserrat"
                      fontSize="14px"
                      color="fontMain"
                      >{{ v2.description }}</CText
                    >
                    <CList
                      styleType="disc"
                      v-if="v2.list && v2.list.length > 0"
                      pl="10px"
                    >
                      <CListItem
                        v-for="(v21, i21) in v2.list"
                        :key="i21"
                        fontFamily="Montserrat"
                        fontSize="14px"
                        color="fontMain"
                        >{{ v21 }}</CListItem
                      >
                    </CList>
                  </CBox>
                  <CBox bgColor="bgTestList" h="40px" />
                  <CBox v-if="v.component" pt="10px" pl="10px">
                    <CText
                      fontFamily="Montserrat"
                      fontSize="14px"
                      color="fontMain"
                      >{{ v.component }}</CText
                    >
                    <CList
                      styleType="disc"
                      v-if="v.component_list && v.component_list.length > 0"
                      pl="10px"
                    >
                      <CListItem
                        v-for="(v13, i) in v.component_list"
                        :key="i"
                        fontFamily="Montserrat"
                        fontSize="14px"
                        color="fontMain"
                        >{{ v13 }}</CListItem
                      >
                    </CList>
                  </CBox>
                </CCollapse>
              </CBox>
            </CBox>
          </CBox>
          <CBox
            bg="white"
            minH="100px"
            borderRadius="10px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox py="20px" mx="20px" v-if="tags != ''">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="20px"
                fontWeight="bold"
                color="fontMain"
              >
                Your Submission History
              </CText>
              <CGrid
                :templateColumns="[
                  '6fr 6fr',
                  '6fr 6fr',
                  '6fr 6fr',
                  '2fr 2fr 2fr 3fr 2fr',
                ]"
                :gap="4"
                h="inherit"
                pt="3"
              >
                <CBox
                  border="1px solid #E2E8F0"
                  W="100%"
                  borderRadius="8px"
                  px="3"
                  py="2"
                >
                  <CText
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontWeight="500"
                    display="block"
                    color="#9e9e9e"
                    mb="1"
                  >
                    Total Submit
                  </CText>
                  <CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="600"
                    color="fontMain"
                  >
                    {{ currentMetricsSummary.total || 0 }}
                  </CText>
                </CBox>
                <CBox
                  border="1px solid #E2E8F0"
                  W="100%"
                  borderRadius="8px"
                  px="3"
                  py="2"
                  v-for="(r, i) of currentTestMetricsName"
                  :key="i"
                >
                  <CText
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontWeight="500"
                    display="block"
                    color="fontMain"
                    mb="1"
                  >
                    Best {{ r }}
                  </CText>
                  <CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="600"
                    color="fontMain"
                  >
                    {{ formatMetrics(currentMetricsSummary[r], r) }}
                  </CText>
                  <CText
                    fontFamily="Montserrat"
                    fontSize="11px"
                    fontWeight="400"
                    color="#9e9e9e"
                  >
                    (based on average top 5 result)
                  </CText>
                </CBox>
              </CGrid>
            </CBox>
            <CDivider />
            <MetricsSummaryByTag
              v-if="tags == ''"
              @detailTags="detailTags"
              :metricsName="currentTestMetricsName"
              :metricsSummaryByTag="currentMetricsSummaryByTag"
            ></MetricsSummaryByTag>
            <CBox bg="bgSocialBadge" h="37px" v-if="tags != ''">
              <CGrid
                v-bind:templateColumns="templateColumns"
                :gap="2"
                h="inherit"
              >
                <CBox align="center" py="auto" my="auto">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="11px"
                    fontWeight="bold"
                    color="headerTable"
                    >Rank</CText
                  >
                </CBox>
                <CBox align="center" py="auto" my="auto">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="11px"
                    fontWeight="bold"
                    color="headerTable"
                    >ID</CText
                  >
                </CBox>
                <!-- <CBox align="center" py="auto" my="auto">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="11px"
                    fontWeight="bold"
                    color="headerTable"
                    >State</CText
                  >
                </CBox>
                <CBox py="auto" my="auto">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="11px"
                    fontWeight="bold"
                    color="headerTable"
                    >DATE</CText
                  >
                </CBox> -->
                <CBox
                  py="auto"
                  my="auto"
                  v-for="(r, i) of currentTestMetricsName"
                  :key="i"
                >
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="11px"
                    fontWeight="bold"
                    color="headerTable"
                    >{{ r }}</CText
                  >
                </CBox>
                <CBox py="auto" my="auto">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="11px"
                    fontWeight="bold"
                    color="headerTable"
                    >Hashtags</CText
                  >
                </CBox>
              </CGrid>
            </CBox>
            <CBox v-if="tags != ''">
              <CBox v-for="(v, i) in tests" :key="i">
                <CGrid
                  v-bind:templateColumns="templateColumns"
                  :gap="2"
                  h="inherit"
                >
                  <CBox align="center" py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="12px"
                      fontWeight="bold"
                      >{{ i + 1 }}</CText
                    >
                  </CBox>
                  <CBox align="left" py="auto" my="auto">
                    <CText fontStyle="normal" fontSize="14px">{{
                      v.site_name
                    }}</CText>
                    <CText fontStyle="normal" fontSize="12px"
                      >{{ v.id }} {{ v.metrics_state }}</CText
                    >
                    <CText
                      fontStyle="normal"
                      fontSize="11px"
                      fontWeight="400"
                      color="#9e9e9e"
                      >{{ v.created_at_str }}</CText
                    >
                    <!-- <CText                      
                      fontStyle="normal"
                      fontSize="10px"                      
                      >{{v.site_name}}</CText
                    > -->
                  </CBox>
                  <!-- <CBox align="center" py="auto" my="auto">
                    
                  </CBox> -->
                  <!-- <CBox py="auto" my="auto">
                    <CText
                      fontStyle="normal"
                      fontSize="12px"
                      fontWeight="600"
                      color="#9e9e9e"
                      >{{ v.created_at_str }}</CText
                    >
                  </CBox> -->
                  <CBox
                    py="auto"
                    my="auto"
                    v-for="(r, i) of currentTestMetricsName"
                    :key="i"
                  >
                    <CText fontStyle="normal" fontSize="14px">{{
                      formatMetrics(v.metrics_value[r], r)
                    }}</CText>
                  </CBox>
                  <!-- <CBox py="auto" my="auto">
                    <CText                      
                      fontStyle="normal"
                      fontSize="14px"                      
                      >{{ Math.round(v.metrics.CPU * 100) / 100 }}%</CText
                    >
                  </CBox>
                  <CBox py="auto" my="auto">
                    <CText                      
                      fontStyle="normal"       
                      fontSize="14px"                                                        
                      >{{ parseFloat(v.metrics.Memory / 1000000).toFixed(2)  }} MB</CText
                    >
                  </CBox>
                  <CBox py="auto" my="auto">
                    <CText                      
                      fontStyle="normal"
                      fontSize="14px"                      
                      >{{ parseFloat(v.metrics['Time Duration'] / 1000).toFixed(2)   }}s</CText
                    >
                  </CBox>    -->
                  <CBox py="auto" my="auto">
                    <CBox my="2" display="inline-flex">
                      <CText
                        v-for="(tag, k) in v.hashtags"
                        :key="k"
                        fontFamily="Montserrat"
                        fontSize="12px"
                        fontWeight="600"
                        color="activeMenu"
                        mr="1"
                      >
                        <span
                          class="pointer"
                          @click="
                            currentAppType.view_type != 'RECRUITMENT' &&
                              $router.push({
                                name: 'Leaderboard',
                                params: { id: appTypeId },
                                query: { tag: tag.name },
                              })
                          "
                          >#{{ tag.name }}
                        </span>
                        <span v-if="v.hashtags.length !== k + 1">,</span>
                      </CText>
                    </CBox>
                  </CBox>
                </CGrid>
                <CDivider />
              </CBox>
            </CBox>
            <CBox
              mt="20px"
              align="center"
              v-if="meta.max_current_page < meta.total && tags != ''"
            >
              <CText
                v-if="!isLoadingTest"
                fontFamily="Montserrat"
                fontSize="16px"
                fontWeight="500"
                color="activeMenu"
                class="pointer"
                @click.native="nextData()"
                >Load More ...</CText
              >
              <CSpinner v-else />
            </CBox>
            <CBox h="30px" />
          </CBox>
        </CBox>
      </CGrid>
    </CBox>
  </CBox>
</template>

<script>
import { CBox, CGrid, CText, CStack, CImage } from "@chakra-ui/vue";
import { GET_APP_TYPE, GET_APP_METRICS } from "@/store/app-types.module";
// import { GET_TEST_SCENARIOS } from "@/store/test-scenarios.module";
import {
  GET_METRICS,
  GET_METRICS_SUMMARY,
  UPLOAD_APP,
  GET_TEST_HASHTAG_FILTERS,
  GET_METRICS_SUMMARY_BYTAG,
} from "@/store/test.module";
import Loading from "vue-loading-overlay";
// import Loading2 from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, url } from "vuelidate/lib/validators";
// import Leaderboard from "@/components/Leaderboard.vue";
import HashtagFilterApp from "@/components/HashtagFilterApp.vue";
import MetricsSummaryByTag from "@/components/MetricsSummaryByTag.vue";
import _ from "lodash";
import config from "@/ConfigProvider";
import ApiService from "@/store/api.service";

export default {
  metaInfo() {
    const appName = this.currentAppType.name;
    return {
      title: this.currentAppType.name
        ? this.capitalizeFirstLetter(appName)
        : "",
      titleTemplate: this.currentAppType.name ? "Devcode - %s" : "Devcode",
    };
  },
  // metaInfo: {
  //   title: this.currentSlug,
  //   titleTemplate: "Devcode - %s"
  // },
  name: "Competition",
  components: {
    CBox,
    CGrid,
    CText,
    CStack,
    CImage,
    Loading,
    // Loading2,
    // Leaderboard,
    HashtagFilterApp,
    MetricsSummaryByTag,
  },
  mixins: [validationMixin],
  data() {
    return {
      tags: "",
      isLoading: false,
      form: {
        siteName: "",
        siteUrl: "",
        driver: "",
        hashtags: [],
      },
      statusForm: {
        siteName: false,
        siteUrl: false,
        driver: false,
      },
      tests: [],
      defaultLabelMetrics: 0,
      meta: {},
      isLoadingTest: false,
      testScenarios: [],
      page: 1,
      limit: 10,
      selectedTagList: [],
    };
  },
  watch: {
    // tests: function (current) {
    //   // set last hashtags field by current submission hashtags from history
    //   let me = this;
    //   if (me.form.hashtags.length < 1 && current.length > 0) {
    //     const { hashtags } = current[0];
    //     hashtags.map(({ name }) => {
    //       me.$refs.hashtagInput.select2
    //         .append(new Option(name, name, false, false))
    //         .trigger("change");
    //       me.form.hashtags.push(name);
    //     });
    //   }
    // },
  },
  created() {
    const dataLayer = window.dataLayer || [];
    const gtag = () => dataLayer.push(arguments);
    gtag("js", new Date());
    gtag("config", "G-F2KHZP9RQ6");
    this.getData();
  },
  mounted() {
    // if (this.appTypeId == 0) {
    //   this.$router.push({ name: "404" });
    //   return;
    // }
    // prevent hashtag input only alpha letters characters
    // setTimeout(() => {
    //   this.$refs.hashtagInput.select2
    //     .data("select2")
    //     .selection.$search.on("keypress", function (e) {
    //       return (
    //         (e.charCode > 64 && e.charCode < 91) ||
    //         (e.charCode > 96 && e.charCode < 123) ||
    //         e.charCode == 32
    //       );
    //     });
    // }, 500);
  },
  computed: {
    templateColumns() {
      const itemLength =
        this.$store.getters.currentAppMetrics[
          this.defaultLabelMetrics
        ].name.split(",").length;
      const output = "1fr 5fr " + " 2fr ".repeat(itemLength) + " 2fr ";
      return output;
    },
    currentMetricsSummary() {
      return this.$store.getters.currentMetricsSummary;
    },
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
    currentTestScenarios() {
      return this.$store.getters.currentTestScenarios;
    },
    currentTestMetrics() {
      return this.$store.getters.currentAppMetrics;
    },
    currentTestMetricsName() {
      return this.$store.getters.currentAppMetrics[
        this.defaultLabelMetrics
      ].name.split(",");
    },
    currentMetricsSummaryByTag() {
      return this.$store.getters.currentMetricsSummaryByTag;
    },
    appTypeId() {
      // let id = 0;
      // switch (this.$route.params.id) {
      //   case "youtube":
      //     id = 1;
      //     break;
      //   case "calculator":
      //     id = 2;
      //     break;
      //   case "todo":
      //     id = 3;
      //     break;
      //   case "api-todo":
      //     id = 4;
      //     break;
      //   case "mobile-todo":
      //     id = 5;
      //     break;
      //   case "pos-system":
      //     id = 8;
      //     break;
      //   default:
      //     id = 0;
      //     break;
      // }
      // return id;
      //return this.$route.params.id;
      return this.currentAppType.id;
    },
    formValidation() {
      const validation = {
        siteName: { required },
        siteUrl: { required },
      };
      if (this.currentAppType.type === "FE") {
        Object.assign(validation.siteUrl, { url });
      }
      if (this.currentAppType.type === "MOBILE") {
        Object.assign(validation, { driver: { required } });
      }
      // } else if (this.currentAppType.type === "BE") {
      //   Object.assign(validation.siteUrl, {
      //     url: function (value) {
      //       const url =
      //         /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
      //           value
      //         );
      //       return url;
      //     },
      //   });
      // }
      return validation;
    },
    currentProgressBar() {
      return this.$store.getters.currentProgressBar;
    },
    currentTestSummary() {
      return this.$store.getters.currentTestSummary;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    changeTags(value) {
      this.tags = value;
      this.getTests();
    },
    detailTags(value) {
      this.tags = value;
      this.getTests();
    },
    formatMetrics(value, label) {
      switch (label) {
        case "CPU":
          return Math.round(value * 100) / 100 + "%";
        case "Memory":
          return parseFloat(value / 1000000).toFixed(2) + "MB";
        case "Time Duration":
          return parseFloat(value / 1000).toFixed(2) + "s";
        case "Request per Second":
          return Math.round(value) + "";
        default:
          return value + "";
      }
    },
    openApp(url) {
      window.open(url, "_blank");
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    async uploadProcess(event) {
      try {
        const upload = await this.$store.dispatch(UPLOAD_APP, {
          data: event.target.files[0],
          progressName: "progressApp",
        });
        this.form.siteUrl = upload.location;
      } catch (err) {
        let errData = err.message;
        if (err.response && err.response.data && err.response.data.error)
          errData = err.response.data.error;
        Swal.fire({
          title: "Oops . .",
          text: errData,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    changeLabelMetrics(index) {
      this.defaultLabelMetrics = index;
      this.page = 1;
      this.tags = "";
      // this.getTests(true);
      this.getMetricsSummaryByTag();
    },
    nextData() {
      this.page = this.page + 1;
      this.getTests(true);
    },
    checkEmpObject(obj) {
      return _.isEmpty(obj);
    },
    changeStatus(name) {
      this.statusForm[name] = true;
    },
    async getMetricsSummaryByTag() {
      const tags = this.$store.getters.currentHashtags;
      const promiseAllTags = [];
      const testMetrics = this.currentTestMetrics[this.defaultLabelMetrics];

      this.$store.state.test.metricsSummaryByTag = [];

      for (const tag of tags) {
        promiseAllTags.push(
          this.$store.dispatch(GET_METRICS_SUMMARY_BYTAG, {
            appTypeId: this.appTypeId,
            page: this.page,
            limit: this.limit,
            tags: tag.tag.name,
            // name: 'CPU,Memory,Time Duration',
            // label: 'Application Execution',
            // sortBy: 'Time Duration',
            // sort: 'ASC'
            ...testMetrics,
          })
        );
      }
      await Promise.all(promiseAllTags);
      this.$store.state.test.metricsSummaryByTag.sort((a, b) => {
        if (a["Time Duration"] < b["Time Duration"]) {
          return -1;
        } else {
          return 1;
        }
      });
    },
    async getData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(GET_APP_TYPE, this.$route.params.slug);
        await this.$store.dispatch(
          GET_APP_METRICS,
          this.currentAppType.app_name
        );
        await this.$store.dispatch(GET_TEST_HASHTAG_FILTERS, {
          app_type_id: this.currentAppType.id,
          limit: 100,
        });
        this.getMetricsSummaryByTag();
        // this.getTests();
        // console.log('test',this.currentAppType)
        this.isLoading = false;

        const formData = new URLSearchParams();
        formData.append("app_type_id", this.appTypeId);
        ApiService.put("/app-type-view", formData);
      } catch (err) {
        this.isLoading = false;
        if (err.code === 401) {
          Swal.fire({
            text: "Maaf, anda perlu login di GetHired.id terlebih dahulu. Akses halaman ini kembali setelah anda melakukan Login pada GetHired.id",
            heightAuto: true,
            willClose: () => {
              window.location.href =
                config.value("GETHIRED_WEB_URL") + "/employee/profile";
            },
          });
        }
        console.log(err);
        // Swal.fire({
        //   title: "Hi ..",
        //   text: "Selamat datang di Devcode perhatikan requirement dan detail test scenario yang ada",
        //   heightAuto: true,
        //   willClose: () => {
        //     this.reloadData();
        //   },
        // });
      }
    },
    reloadData() {
      this.getData();
    },
    async getTests(showError = false) {
      try {
        this.isLoadingTest = true;
        const testMetrics = this.currentTestMetrics[this.defaultLabelMetrics];

        await Promise.all([
          this.$store.dispatch(GET_METRICS_SUMMARY, {
            appTypeId: this.appTypeId,
            page: this.page,
            limit: this.limit,
            tags: this.tags,
            // name: 'CPU,Memory,Time Duration',
            // label: 'Application Execution',
            // sortBy: 'Time Duration',
            // sort: 'ASC'
            ...testMetrics,
          }),
          this.$store.dispatch(GET_METRICS, {
            appTypeId: this.appTypeId,
            page: this.page,
            limit: this.limit,
            tags: this.tags,
            // name: 'CPU,Memory,Time Duration',
            // label: 'Application Execution',
            // sortBy: 'Time Duration',
            // sort: 'ASC'
            ...testMetrics,
          }),
        ]);
        const tests = this.$store.getters.currentTests;
        this.isLoadingTest = false;
        this.tests = tests.data;
        this.meta = tests.meta;
      } catch (err) {
        this.isLoadingTest = false;
        if (showError) {
          Swal.fire({
            title: "Oops . .",
            text: err.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      }
    },
  },
  validations() {
    return {
      form: this.formValidation,
    };
  },
};
</script>
